main {
  width: 70%;

  margin: 100px auto 100px auto;
  text-align: center;
}

.alert {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: 1px solid tomato;
  border-radius: 6px;
  height: 70px;
  width: 80%;
  margin: 80px auto -50px auto !important;
}

.alert p {
  padding-bottom: 12px;
  color: tomato;
  word-break: break-word !important;
  /* 長いテキストを改行するために word-break を使用します */
  text-align: center !important;
  /* テキストを中央寄せします */
  width: 100% !important;
}

.thanks-page {
  width: 70%;

  margin: 200px auto 0px auto !important;
  text-align: center;
}

.element {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.parent-container {
  display: flex !important;
  justify-content: center !important;
}

.child-container {
  display: flex !important;
  align-items: center !important;
}

h1 {
  font-size: 24px;
  color: "#3333333";
  font-weight: bold;
  margin-top: 140px;
  margin-bottom: 40px;
}

p {
  font-size: 15px;
  color: "#3333333";
  font-weight: bold;
  margin-top: 1em;
}

input {
  height: 40px;
  border: 1px solid #ccc;
  color: #555;
  padding: 10px 16px;
  background-color: #fff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  font-size: 18px;
  line-height: 1.33;
  border-radius: 6px;
  /* transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; */
}

input:focus {
  outline: none;
  border: 1px solid #2d8cff;
  box-shadow: 0 0 4px 1px #2d8cff;
}

.modal-button2 {
  margin: 30px 30px 0px 140px;
  background-color: #2d8cff;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

main button {
  margin-top: 30px;
  background-color: #2d8cff;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

main button:hover {
  background-color: #91c2ff !important;
}

.modal-button:hover {
  background-color: #91c2ff !important;
}

/* zoomフッター削除 */
.page-footer {
  display: none !important;
}

/* オーディオ設定画面の規約プライバシーポリシーリンク文字列非表示PC・スマートフォン共通スタイル */
.preview-agreement {
  font-size: 0 !important;
  margin: 10px 0 -30px 0 !important;
}

/* PC版のオーディオ設定画面の規約プライバシーポリシーリンク文言変更スタイル */
@media only screen and (min-width: 768px) {
  .preview-agreement {
    width: 118%;
    margin: 0 -30px -30px 0 !important;
  }

  .preview-meeting-info.empty-field:before {
    content: "・オーディオ設定（上部マイクアイコン）をONにしてください\A" !important;
    white-space: pre-wrap;
    font-size: 15px;
    width: 118% !important;
    margin: 10px -30px 0 0 !important;
    font-weight: bold;
    color: tomato;
  }

  .preview-agreement:before {
    content: '    ※｢"virtual-connect.jp"がマイクへのアクセスを求めています」と表示された場合、\A       「許可」をタップしてONに切り替え\A' !important;
    white-space: pre-wrap;
    font-size: 12px;
    font-weight: normal;
    color: tomato;
  }

  .preview-agreement::after {
    content: "・カメラ設定（上部ビデオアイコン）のON/OFFは任意です\A・　「参加」をタップしてご参加ください" !important;
    white-space: pre-wrap;
    font-size: 15px;
    font-weight: bold;
    color: tomato;
  }
}

/* スマートフォン版オーディオ設定画面の規約プライバシーポリシーリンク文言変更スタイル */
@media only screen and (max-width: 767px) {
  .preview-agreement {
    width: 70% !important;
  }

  .preview-meeting-info.empty-field:before {
    content: "・オーディオ設定（上部マイクアイコン）をONにしてください\A" !important;
    white-space: pre-wrap;
    font-size: 12px;
    width: 70% !important;
    margin: 10px -0px -5px 0 !important;
    font-weight: bold;
    color: tomato;
  }

  .preview-agreement:before {
    content: '   ※｢"virtual-connect.jp"がマイクへのアクセスを求めています」と表示された場合、「許可」をタップしてONに切り替え\A' !important;
    white-space: pre-wrap;
    font-size: 10px;
    font-weight: normal;
    color: tomato;
  }

  .preview-agreement::after {
    content: "・カメラ設定（上部ビデオアイコン）のON/OFFは任意です\A・ 「参加」をタップしてご参加ください" !important;
    white-space: pre-wrap;
    font-size: 12px;
    font-weight: bold;
    color: tomato;
  }
}

.zoom-workplace-logo {
  display: none;
}

.notification-message-wrap.notification-consent-banner {
  display: none;
}