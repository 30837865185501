body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  min-width: 0 !important;
}

#zmmtg-root {
  display: none;
}

ol {
  margin: 0;
  padding: 0;
}

ol li {
  list-style: none;
  padding-left: 1.3em;
  text-indent: -1.3em;
}